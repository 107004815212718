<template>
	<div style="overflow: hidden;">
		<router-view data-aos="fade-down" data-aos-easing="linear" data-aos-duration="0"></router-view>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				navlist:[]
			}
		},
		components: {
		}
	}
</script>

<style lang="scss">
	.ssf{
		height: 100%;
		overflow-y: auto;
	}
</style>